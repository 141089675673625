/* tslint:disable */
/* eslint-disable */
/**
 * Ticketing Portal
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PricingCategoryByIdReadResponsePricingCategoryTranslationsInner } from './PricingCategoryByIdReadResponsePricingCategoryTranslationsInner';
import {
  PricingCategoryByIdReadResponsePricingCategoryTranslationsInnerFromJSON,
  PricingCategoryByIdReadResponsePricingCategoryTranslationsInnerFromJSONTyped,
  PricingCategoryByIdReadResponsePricingCategoryTranslationsInnerToJSON
} from './PricingCategoryByIdReadResponsePricingCategoryTranslationsInner';

/**
 *
 * @export
 * @interface PricingCategoryByIdReadResponsePricingCategory
 */
export interface PricingCategoryByIdReadResponsePricingCategory {
  /**
   *
   * @type {string}
   * @memberof PricingCategoryByIdReadResponsePricingCategory
   */
  id: string;
  /**
   *
   * @type {number}
   * @memberof PricingCategoryByIdReadResponsePricingCategory
   */
  min_age: number;
  /**
   *
   * @type {number}
   * @memberof PricingCategoryByIdReadResponsePricingCategory
   */
  max_age: number;
  /**
   *
   * @type {string}
   * @memberof PricingCategoryByIdReadResponsePricingCategory
   */
  is_group: string;
  /**
   *
   * @type {number}
   * @memberof PricingCategoryByIdReadResponsePricingCategory
   */
  occupancy_per_unit: number;
  /**
   *
   * @type {number}
   * @memberof PricingCategoryByIdReadResponsePricingCategory
   */
  min_participants: number;
  /**
   *
   * @type {number}
   * @memberof PricingCategoryByIdReadResponsePricingCategory
   */
  max_participants: number | null;
  /**
   *
   * @type {Array<PricingCategoryByIdReadResponsePricingCategoryTranslationsInner>}
   * @memberof PricingCategoryByIdReadResponsePricingCategory
   */
  translations: Array<PricingCategoryByIdReadResponsePricingCategoryTranslationsInner>;
}

/**
 * Check if a given object implements the PricingCategoryByIdReadResponsePricingCategory interface.
 */
export function instanceOfPricingCategoryByIdReadResponsePricingCategory(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'id' in value;
  isInstance = isInstance && 'min_age' in value;
  isInstance = isInstance && 'max_age' in value;
  isInstance = isInstance && 'is_group' in value;
  isInstance = isInstance && 'occupancy_per_unit' in value;
  isInstance = isInstance && 'min_participants' in value;
  isInstance = isInstance && 'max_participants' in value;
  isInstance = isInstance && 'translations' in value;

  return isInstance;
}

export function PricingCategoryByIdReadResponsePricingCategoryFromJSON(
  json: any
): PricingCategoryByIdReadResponsePricingCategory {
  return PricingCategoryByIdReadResponsePricingCategoryFromJSONTyped(json, false);
}

export function PricingCategoryByIdReadResponsePricingCategoryFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): PricingCategoryByIdReadResponsePricingCategory {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    min_age: json['min_age'],
    max_age: json['max_age'],
    is_group: json['is_group'],
    occupancy_per_unit: json['occupancy_per_unit'],
    min_participants: json['min_participants'],
    max_participants: json['max_participants'],
    translations: (json['translations'] as Array<any>).map(
      PricingCategoryByIdReadResponsePricingCategoryTranslationsInnerFromJSON
    )
  };
}

export function PricingCategoryByIdReadResponsePricingCategoryToJSON(
  value?: PricingCategoryByIdReadResponsePricingCategory | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    min_age: value.min_age,
    max_age: value.max_age,
    is_group: value.is_group,
    occupancy_per_unit: value.occupancy_per_unit,
    min_participants: value.min_participants,
    max_participants: value.max_participants,
    translations: (value.translations as Array<any>).map(
      PricingCategoryByIdReadResponsePricingCategoryTranslationsInnerToJSON
    )
  };
}
